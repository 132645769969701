/* url() format('opentype'), */
/* url() format('opentype'), */
/* url() format('opentype'), */
/* url() format('opentype'), */
/* url() format('opentype'), */
/* url() format('opentype'), */
@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Bold.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Heavy.otf) format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Light.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Regular.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-SemiBold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer XH';
  src: local('Haffer XH'),
    url(./fonts/H4FF3RXH/OTF/HafferXH-Thin.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}



.main-left {
  margin-left: 270px !important;
  padding: 30px 70px;
  width: 80%;
}

.qwerty111 {
  padding: 15px 20px !important;
  cursor: pointer;
}

.the-parent-sidebar {
  display: flex;
  width: 100%;
}

.the-journalist-data {
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

.leak-replies {
  margin-bottom: 20px;
  display: flex;
}

.leak-replies img {
  border-radius: 50px;
  width: 68px;
  height: 68px;
  margin-right: 20px;
}

.my-leak-text {
  font-size: 22px;
  color: #64748B;
  font-weight: 500;
  line-height: 28px;
}

.leak-reply-subname {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  max-width: 900px !important;
}

/* .active .nav-link {
  color: #007bff !important;
} */
.koconnected-img {
  padding: .5rem 0 .5rem 1.5rem;
  width: 70px;
}

.nothing-leaks {
  height: 50vh;
}



.nothing-leaks h6 {
  font-size: 32px;
  text-align: center;
  margin-top: 50px;
}

.paul-subnamedate {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-left: 0px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.leak-reply-name {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 800;
}

.the-leak-parent-div {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
  padding: 50px;
  margin-top: 50px;
  border: 1px solid #007bff;
}

.toggle-left-container {
  text-align: end;
}

.cat-new-options-div {
  display: flex;
}

.file-format {
  display: flex;
}

.the-leak-header-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 34.8px;
  color: #1F1D1D;
  max-width: 1032;
}

.tailwind {
  margin-right: 10px;
}

.categories-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  columns: #000000;
  margin-top: 30px;
}

.category-display-div {
  display: flex;
}

.cat-new-options {
  background-color: #F6F6F6;
  color: #50607B;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.the-leak-header-subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #64748B;
  max-width: 1088;
}

.section-title1 {
  margin-top: 30px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000000 !important;
}

.main-background {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: linear-gradient(to bottom,
      #EFF8FF 0%,
      #EFF8FF 40%,
      #F1F9FF 40%,
      #F1F9FF 60%,
      #F2F9FF 60%,
      #F2F9FF 80%,
      #F5FAFF 80%,
      #F5FAFF 90%,
      #F8FCFF 90%,
      #F8FCFF 100%),
    linear-gradient(to right,
      #F8FCFF 0%,
      #F8FCFF 30%,
      #F8FCFF 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.sign-up-btn {
  @apply py-[1.1rem] px-[2.7rem] bg-primary text-white font-medium rounded-lg text-[1.4rem];
}

a:hover {
  text-decoration: none !important;
}

.swal2-title {
  font-size: 18px;
  padding: 30px 20px 0px 20px;
  color: #001D53;
}

.swal2-popup {
  padding-bottom: 30px;

}

.swal2-confirm {
  background-color: red !important;
}

.swal2-confirm:focus {
  box-shadow: none;
}

.text-red-500 {
  color: red !important;
  font-size: 16px;
}

.signup-container {
  text-align: center;
}

.logo-img {
  margin-top: 5%;
  width: 150px;
}

.sign-up-form {
  background-color: #FFFFFF;
  padding: 28px 28px 28px 28px;
  width: 40%;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid #E9EDF2;
  box-shadow: 4px 8px 16px 0px #2447710F;
}

.sign-up-form1 {
  background-color: #FFFFFF;
  padding: 48px 48px 48px 48px;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid #E9EDF2;
  box-shadow: 4px 8px 16px 0px #2447710F;
}

.nav-link {
  color: #1F1D1D !important;
}

.sign-up-form-text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #101928;
  margin-bottom: 35px;
}

.input-container {
  max-width: 280px;
  margin: auto;
}



.sign-up-form-text1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #101928;
}

.signup-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  color: #101928;
  /* text-decoration: none; */
}

.signup-label-placeholder {
  font-size: 12px;
  font-weight: 300;
  line-height: 20.3px;
  color: red;
  /* text-decoration: none; */
}

.sign-up-input {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
}

.sign-up-input44 {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  background-color: #F9F9F9;
}

.sign-up-input-textarea .css-13cymwt-control {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}

.sign-up-input-textarea .css-t3ipsp-control {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}


.sign-up-input-textarea .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.sign-up-input-textareaas .css-13cymwt-control {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}


.form-controller {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: none !important;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.form-controller1 {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: #F9F9F9 !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #64748B !important;

}

.category-flex {
  display: flex;
}

.category-flex img {
  margin-right: 10px;
}

.category-flex input {
  font-size: 14px;
  color: #A8B3C7 !important;
  font-weight: 400;
}


.category-flex1 {
  display: flex;
}

.category-flex1 img {
  margin-right: 10px;
}

.category-flex1 input {
  font-size: 14px;
  color: #A8B3C7 !important;
  font-weight: 400;
}

.form-controller:focus-visible {
  outline: none;
}

textarea {
  outline: none;
}

input {
  outline: none;
}

.category-flex textarea {
  font-size: 14px;
  color: #A8B3C7 !important;
  font-weight: 400;
}

.sign-up-btn {
  width: 100%;
  background-color: #0C78F1;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
}

.create-query-div {
  text-align: end;
}

.create-query-btn {
  /* width: 100%; */
  background-color: #0C78F1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
}


.create-query-btn-one {
  /* width: 100%; */
  background-color: #0C78F1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
  width: 100%;
}

.create {
  text-align: center;
}

.sign-up-btn1 {
  background-color: #0C78F1;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 12px 24px 12px 24px;
  width: 40%;
  margin-top: 70px;
}

.sign-up-form-text-sub {
  font-size: 16px;
  color: #667185;
  line-height: 23.2px;
  text-align: center;
  margin-bottom: 35px;
}



.sign-up-form-text-sub-small {
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
}

.sign-up-form-text-sub-small-login {
  font-size: 14px;
  color: #5A5F67;
  line-height: 20.3px;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 25px;
  text-decoration: none;
}

.terms-text {
  /* text-decoration: none; */
  color: #0C78F1;
}

/* .verify-contain {
  text-align: center;
} */

.verify-heading {
  margin-top: 40px;
  font-size: 48px;
  color: #001D53;
  letter-spacing: -0.4px;
  font-weight: 600;
  text-align: center;
}


.verify-heading-sub {
  font-size: 18px;
  color: #5A5F67;
  letter-spacing: 0.2px;
  font-weight: 400;
  max-width: 431px;
  margin: auto;
  text-align: center;
}

.has-pwd-icon {
  position: relative;
}

.view-pwd {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 15px;
  color: #8a8383;
  cursor: pointer;
}

.who-flex {
  display: flex;
  justify-content: space-evenly;
  margin-top: 70px;
}

.who-link {
  text-decoration: none;
}

.who-link p {
  color: #001D53;
  margin-top: 10px;
  text-align: center;
  font-size: 24px;
}

.choose-img {
  border: 1px solid #D5E3F3;
  padding: 20px;
  border-radius: 50%;
  /* background-color: #D5E3F3; */
}

.save-and-continue-contain {
  width: 300px;
  margin: auto;
}

.save-and-continue {
  width: 100%;
  background-color: #0C78F1;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  padding: 16px 24px 16px 24px;

}



.profile-logo {
  width: 100px;
  margin-right: 35px;
}

.navbar-bcg {
  background-color: #ffffff;
  box-shadow: 4px 4px 32px 0px #2447710F;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  /* margin-bottom: 50px; */
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #1F1D1D;
}

.profile-person-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.for-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

}

.good-greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #001D53;
  font-size: 32px;
  font-weight: 600;
  /* margin-top: 50px; */
}

.create-a-query {
  background-color: #0C78F1;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  padding: 12px 30px;
  font-family: "Haffer XH" !important;
}

.my-queries-sub {
  color: #50607B;
  font-weight: 500;
  font-size: 22px;
  line-height: 28.6px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.custom-query-table {
  margin-top: 50px;
}

.good-greet-div {
  margin-top: 50px;
  margin-bottom: 50px;
}

.personal-info {
  margin-bottom: 30px;
  color: #50607B;
}

.query-details-container {
  margin-top: 50px;
  padding: 50px 50px;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
}

.query-details-container1 {
  margin-top: 20px;
  padding: 20px 50px;
  border-radius: 20px;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border: 1px solid #007bff;
}

/* .react-tabs__tab {
  border-bottom: 1px solid #aaa !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.react-tabs__tab--selected {
  border-bottom: 1px solid #437EF7 !important;
} */
.extended-div-container {
  display: flex;
  justify-content: space-between;
}

.extended-line {
  display: flex !important;
}

.extended-line1 {
  display: flex;
  margin-left: 10px;
}

.extended-header {
  font-size: 16px;
  color: #50607B;
  line-height: 20.8px;
  font-weight: 400;
  margin-bottom: 0px;
}

.person-detail {
  display: flex;
  margin-top: 50px;
}

.extended-img {
  font-size: 16px;
  margin-right: 5px;
}

.extended-mainline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.extended-admin-text {
  max-width: 636px;
  color: #1F1D1D;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.video-play-icon1 {
  font-size: 20px;
  /* margin-right: 10px; */
}

.extended-img-person {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 50px;
  width: 80px;
  height: 80px;
}

.person-image {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #50607B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 0px;
}

.awaiting-review {
  display: flex;
}

.nav-tabsub {
  border-bottom: 2px solid #EAEBF0 !important;
  padding: 10px;
}

.status-status {
  margin-top: 50px;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
}

.awaiting-text {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0px;
  color: #00ABE4;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-transform: capitalize;
}

.place-clock {
  width: 20px;
  height: 20px;
}

.query-description {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  line-height: 23.4px;
  margin-top: 50px;
}

.query-sub-description {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #64748B;
  /* white-space: pre-wrap; */
  overflow-wrap: break-word;
  /* Allow text to break between characters */
}

.input-comment-text-div {
  border-top: 1px solid #E9EDF2;
  border-bottom: 1px solid #E9EDF2;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  margin-top: 10px;
}

.submit-reply {
  background-color: #0C78F1;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  padding: 15px 35px;
  font-size: 18px;
}

.input-comment-text {
  border: none;
  width: 100%;
}

.response-div {
  display: flex;
}

.response-div img {
  border-radius: 50px;
  width: 68px;
  height: 68px;
}

.paul-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #50607B;
  margin-left: 10px;
  margin-bottom: 0px;
}

.paul-subname {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-left: 10px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.paul-subnames {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.paul-subname1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #64748B;
  margin-bottom: 0px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-top: 20px;
}

.reply {
  margin-top: 20px;
  color: red;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 500;
  cursor: pointer;
}

.cursor-pointer {
  color: #50607B;
  text-decoration: none;
}

.cursor-pointer:hover {
  color: #50607B;
  text-decoration: none;
}

.nav-iteming .active {
  border-bottom: 2px solid #437EF7 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #437EF7 !important;
}

.nav-linking {
  border-bottom: 2px solid #EAEBF0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #A8B3C7 !important;
  font-size: 16px;
  padding: 12px 12px 14px 12px;
  text-decoration: none;
}



.nav-linking:hover {
  border-bottom: 2px solid #437EF7 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  color: #437EF7 !important;
  text-decoration: none;
}

a:focus-visible {
  outline: none;
}

.nav-link:focus {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
}

.nav-iteming .nav-linking {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
}

.no-result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50vh; */
  /* Optionally, adjust the height of the container */
}

.dropdown-menu {
  margin-top: 35px !important;
  margin-left: -200px !important;
  padding: 20px !important;
  box-shadow: 8px 12px 24px 0px #2447710F !important;
  border: 1px solid #F3F3F3 !important;
  width: 300px !important;
}

.dropdown-container-profile {
  text-align: center;
}

.dropdown-container-profile-name {
  font-size: 18px;
  color: #001D53;
  margin-top: 10px;
}

.dropdown-container-profile-role {
  font-size: 14px;
  color: #50607B;
  letter-spacing: -0.2px;
}

.profile-person-flex {
  display: flex;
  margin-bottom: 10px;
}

.profile-person-flex a {
  font-size: 14px;
  color: #1F1D1D;
  font-weight: 400;
  padding-left: 7px !important;
  max-width: 310px !important;
}

.modal {
  margin-top: 50px;
  height: 800px;
}

.app-modal-content {
  border-radius: 10px;
  padding: 30px 30px;
  background: #fff;
  border: none;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 0 !important;
  border-bottom: 2px solid var(--light-gray);
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header h4.modal-title {
  font-size: 16px;
  color: #0C78F1;
  font-weight: 600;
  letter-spacing: 0.2px;
  /* padding-left: 12px; */
  /* margin-bottom: 20px; */
}

.modal-header .close {
  padding: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.rec-modal-body {
  padding: 0;
}

.modal-xlg {
  width: 1200px !important;
  max-width: 1200px;
}

.modal-lg {
  width: 900px !important;
  max-width: 900px;
}

.modal-normal {
  width: 750px !important;
  max-width: 900px;
}

.modal-xsm {
  width: 400px !important;
  max-width: 400px;
}

.modal-xsm .modal-title {
  font-size: 0.5rem;
}

.modal-subtitle {
  margin-bottom: 30px;
  margin-top: -20px;
  font-size: 14px;
  font-family: inherit;
  color: #5e6c85;
}

.modal-dialog {
  max-width: 90vw;
  max-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff !important;
  border-radius: 10px !important;
}




/* .popup-content {
  padding-block: 5px;
  background-color: white;
  color: #45464e;
  border: 0.5px solid #e1e2e9;
  border-radius: 4px;
}

.popup-option {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

.popup-option:hover {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  cursor: pointer;
} */

.query-label {
  font-size: 14px !important;
  color: #1F1D1D !important;
  margin-top: 30px;
}

.query-input {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  /* text-transform: capitalize !important; */
}

.query-input1 {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  height: 150px;
  /* text-transform: capitalize !important; */
}

.query-modal-div {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border: 1px solid #E9EDF2;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  display: flex;
  background-color: #F9F9F9 !important;
  text-transform: capitalize !important;
}

.query-modal-div-div {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border: 1px solid #E9EDF2;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  display: flex;
}

/* .css-13cymwt-control {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 16px 20px 16px 20px !important;
  text-transform: capitalize !important;
} */


.css-b62m3t-container .css-13cymwt-control {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 9px 20px 9px 20px !important;
  text-transform: capitalize !important;
}

.css-t3ipsp-control {
  font-size: 14px !important;
  color: #1F1D1D !important;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 9px 20px 9px 20px !important;
  text-transform: capitalize !important;
}

.sign-up-input-textareaas .css-t3ipsp-control {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}

.sign-up-input-textareaas .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.sign-up-input-textarea-new {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: block;
  height: 200px;
}

.my-inputing {
  border: none !important;
  background-color: #F9F9F9 !important;
  padding: 0px !important;
  font-size: 14px !important;
  ;
}

.my-inputing:focus-visible {
  outline: none;
}

.type-of-header {
  font-size: 12px;
  color: #667185;
  font-weight: 400;
  margin-top: 15px;
  line-height: 17.4px;
}

.profile-person-flex-bg {
  background-color: #F8FBFF;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}



.dropdown-item {
  white-space: wrap !important;
  text-decoration: none !important;
}

.dropdown-menu-notify {
  font-size: 14px;
  color: #0C78F1;
  font-weight: 600;
  line-height: 20.15px;
  margin-bottom: 25px;
}

.yesterday {
  font-size: 14px;
  color: #001D53;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 15px;
}

.notice {
  font-size: 14px;
  color: #50607B !important;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.2px;
  padding: 0px !important;

}

.account-container {
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border-radius: 20px;
  margin-bottom: 50px;
}

.account-details-edit-container {
  display: flex;
}

.edit-account-details {
  font-size: 14px;
  color: #50607B;
  font-weight: 500;
  line-height: 28.6px;
  margin-top: 40px;
}

.edit-text {
  font-size: 14px;
  color: #0C78F1;
  font-weight: 500;
  margin-top: 42px;
  margin-bottom: 35px;
  margin-left: 20px;
}

.edit-account-name {
  font-size: 14px;
  color: #64748B;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 0px;
}

.edit-account-icon {
  width: 14px;
  margin-right: 5px;
}

.account-details-edit-container-now {
  display: flex;
  margin-bottom: 15px;
}

.indicate-required {
  font-size: 16px;
  font-weight: 400;
  color: #50607B;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-top: 20px;
}


.signup-label1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  color: #101928;
  margin-bottom: 0px;
  margin-top: 30px;
}

.sign-up-input1 {
  padding: 16px 20px 16px 20px !important;
  border: 1px solid #E9EDF2;
  margin-bottom: 20px;
  border-radius: 30px !important;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #98A2B3;
  line-height: 20.3px;
  letter-spacing: 0.3px;
  width: 100%;
  display: flex;
}

.form-controller1 {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: none !important;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.edit-btn-save-div {
  text-align: end;
}

.edit-btn-save {
  border: none;
  border-radius: 8px;
  background-color: #0C78F1;
  color: #ffffff;
  padding: 10px 18px 10px 18px;
  width: 150px;
  font-size: 16px;
}

.data-header {
  margin-top: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: #64748B;
}

.we-keep-header {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 22px;
  color: #64748B;
}

.account-container1 {
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border-radius: 20px;
  margin-bottom: 50px;
}

.no-result-found-text {
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #50607B;
  line-height: 22px;
}

.notify-past {
  font-size: 20px;
  font-weight: 500;
  color: #50607B;
  line-height: 28.6px;
  margin-bottom: 20px;
}

.learn-more-container {
  background-color: #F6FAFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.learn-more-container1 {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 4px 8px 32px 0px #2447710F;
  border: 1px solid #1F1D1D;
}

.learn-more-text {
  font-size: 16px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 25.2px;
  max-width: 800px;
  margin-bottom: 0px;
}

.learn-more-text33 {
  font-size: 10px;
  color: #50607B;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 25.2px;
  max-width: 800px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.learn-more-link {
  /* margin-top: 20px; */
  color: #001D53;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.6px;
  margin-bottom: 0px;
}

.profile-info-container {
  padding: 30px 50px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 4px 8px 32px 0px #2447710F;
}

.unfinished-input {
  padding: 16px 20px !important;
  border-radius: 20px !important;
  border: 1px solid #E9EDF2 !important;
  color: #1F1D1D;
  font-size: 14px !important;
}

.job-title-header {
  font-size: 16px !important;
  color: #001D53;
  font-weight: 500;
  line-height: 20.8px;

}

.tailwind {
  cursor: pointer;
}

.job-title-header1 {
  font-size: 16px !important;
  color: #001D53;
  font-weight: 500;
  line-height: 20.8px;
  /* text-align: center; */
}

.show-public-profile p {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 7px;
  color: #667185;
  font-size: 14px;
  line-height: 20.3px;
}

.show-public-profile img {
  width: 18px;
}

.my-topics-text {
  color: #50607B;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  margin-top: 100px;
}

.divider-div {
  border-top: 1px solid #E9EDF2;
  margin-top: 20px;
  margin-bottom: 50px;
}

/* .pagination {
  float: right;
  margin-top: 25px;
}

.previous-class a {
  color: #000000;
  margin-right: 10px;
}

.next-class a {
  color: #000000;
  margin-left: 10px;
}

.active-paginate {
  margin: 0px 10px 0px 10px;
  background-color: #437EF7;
  color: #ffffff;
  padding: 0px 7px;
  border-radius: 20px;
}

.page-item-class {
  padding: 0px 7px;
} */

/* .small-role {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50px;
  padding: 5px;
}

.small-role1 {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50px;
  padding: 5px;
} */


/* Custom styles for ReactPaginate */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  /* Float to the right */
  margin-top: 35px;
  margin-bottom: 35px;
}

.pagination li {
  margin-left: 5px;
  margin-right: 5px;

}

.pagination li a {
  cursor: pointer;
}

.previous-class,
.next-class {
  margin-left: 10px;
  /* Add space between previous/next and page numbers */
  cursor: pointer;
  color: black;
  /* Default color */
}

.page-number {
  margin-left: 5px;
  /* Add space between pagination numbers */
  cursor: pointer;
  color: black;
  /* Default color */
}

.active-paginate {
  background-color: #007bff;
  /* Background color for active page */
  color: white;
  padding: 0 5px;
  border-radius: 0px;
  /* Text color for active page */
}









.query-description1 {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  line-height: 23.4px;
  margin-top: 0px;
}


.the-deadline-zone {
  color: red;
  margin-left: 5px;
}






















/* .cnlogo {
  vertical-align: top !important;
  display: flex;
  flex-direction: column;
  justify-content: start !important;
} */


h1 {
  font-family: "Haffer XH" !important;
}

h2 {
  font-family: "Haffer XH" !important;
}

h3 {
  font-family: "Haffer XH" !important;
}

h4 {
  font-family: "Haffer XH" !important;
}

h5 {
  font-family: "Haffer XH" !important;
}

p {
  font-family: "Haffer XH" !important;
}

span {
  font-family: "Haffer XH" !important;
}

a {
  font-family: "Haffer XH" !important;
}

label {
  font-family: "Haffer XH" !important;
}

div {
  font-family: "Haffer XH" !important;
}

button {
  font-family: "Haffer XH" !important;
}

input {
  font-family: "Haffer XH" !important;
}

.create-query-btn:disabled {
  position: relative;
  color: transparent !important;
}

.create-query-btn:disabled::before {
  content: "" !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid;
  border-top-color: white;
  -webkit-animation: loader 0.8s linear infinite;
  animation: loader 0.8s linear infinite;
}

.sign-up-btn:disabled {
  position: relative;
  color: transparent !important;
}

.sign-up-btn:disabled::before {
  content: "" !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid;
  border-top-color: white;
  -webkit-animation: loader 0.8s linear infinite;
  animation: loader 0.8s linear infinite;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
}

.profile-image-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.placeholder {
  color: #aaa;
}

.change-button {
  background-color: transparent;
  border-radius: 20px;
  color: #0C78F1;
  border: 1px solid #0C78F1;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.change-button:hover {
  background-color: #0C78F1;
  color: #ffffff;
}

.person-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin: auto;
}

.person-profile-display {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.reset-password {
  display: flex;
  justify-content: space-between;
}

.profile-search-div {
  background-color: #F9F9F9;
  border-radius: 30px;
  color: #656B76;
  font-size: 16px;
  line-height: 16.2px;
  font-weight: 400;
  border: none;
  padding: 10px 20px;
}

.profile-search-div input {
  border: none !important;
  background-color: #F9F9F9;
  padding: 10px;
  width: 80%;
}

.no-result-yet {
  text-align: center;

}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

.no-result-yet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Set the container height to full viewport height */
}

.no-result-yet {
  text-align: center;
}

.the-found-result {
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 20px;
  margin-top: 50px;
}

.image-search-div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
}

.image-search {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;

}

.first_last {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.first_last1 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.the-background-log {
  background-color: #F9F9F9;
  border-radius: 30px;
  padding: 15px 30px;
  display: flex;
  margin-top: 50px;
}

.input-point {
  border: none;
  background-color: #F9F9F9;
  padding-left: 10px;
  width: 100%;
}

/* .dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
} */

@media only screen and (max-width: 880px) {
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .hTjdWb {
    top: 4px !important;
  }

  .the-parent-sidebar {
    display: block !important;
    width: 100%;
  }

  .main-left {
    margin-left: 0px !important;
    padding: 30px 30px;
    width: 100%;
  }

  .categories-text {
    font-size: 16px;
  }

  .my-leak-text {
    font-size: 18px;
  }

  .the-leak-header-text {
    font-size: 20px;
  }

  .the-leak-parent-div {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
    padding: 30px;
    margin-top: 30px;
  }

  .verify-heading {
    color: #001d53;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -.4px;
    margin-top: 120px;
    text-align: center;
  }

  .verify-heading-sub {
    color: #5a5f67;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .2px;
    margin: auto;
    max-width: 431px;
    text-align: center;
    padding: 0px 20px;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .navbar-collapse {
    margin-top: 30px;
  }

  .query-description1 {
    font-size: 14px;
    margin-top: 20px;
  }

  .create-a-query {
    width: 100%;
    margin-top: 10px;
  }

  .sign-up-form {
    background-color: #FFFFFF;
    padding: 28px 28px 28px 28px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    border: 1px solid #E9EDF2;
    box-shadow: 4px 8px 16px 0px #2447710F;
  }

  .extended-div-container {
    display: block;
    justify-content: space-between;
  }

  .query-details-container {
    margin-top: 30px;
    padding: 20px 20px;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
  }

  .navbar-bcg {
    padding: 20px 30px !important;
  }

  .query-details-container1 {
    margin-top: 40px;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 4px 8px 32px 0px #2447710F;
  }

  .query-description {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    line-height: 23.4px;
    margin-top: 30px;
  }

  .for-flex {
    display: block;
    justify-content: space-between;
    margin-top: 30px;
  }

  .my-queries-sub {
    color: #50607B;
    font-weight: 500;
    font-size: 18px;
    line-height: 28.6px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .account-container1 {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 4px 8px 32px 0px #2447710F;
    border-radius: 20px;
    margin-bottom: 50px;
  }

  .dropdown-menu {
    margin-left: 0px !important;
  }

  .good-greeting {
    font-size: 24px;
  }

  .extended-admin-text {
    font-size: 18px;
  }

  .ReactModal__Content--after-open {
    padding: 0px !important;
  }

  .app-modal-content {
    /* padding: 10px !important; */
  }

  .ReactModal__Content {
    inset: 10px !important;
  }
}



/* Loader container to center the loader on the page */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

/* The loader */
.loader {
  border: 8px solid #f3f3f3;
  /* Light gray background */
  border-top: 8px solid #0c78f1;
  /* Blue color for the loader */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.nav-item .active {
  color: #437EF7 !important;
}